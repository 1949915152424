<template lang="">
  <svg
    width="26"
    height="29"
    viewBox="0 0 26 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M21.6201 7.02129C20.5701 2.40129 16.5401 0.321289 13.0001 0.321289C13.0001 0.321289 13.0001 0.321289 12.9901 0.321289C9.4601 0.321289 5.4201 2.39129 4.3701 7.01129C3.2001 12.1713 6.3601 16.5413 9.2201 19.2913C10.2801 20.3113 11.6401 20.8213 13.0001 20.8213C14.3601 20.8213 15.7201 20.3113 16.7701 19.2913C19.6301 16.5413 22.7901 12.1813 21.6201 7.02129ZM13.0001 12.0313C11.2601 12.0313 9.8501 10.6213 9.8501 8.88129C9.8501 7.14129 11.2601 5.73129 13.0001 5.73129C14.7401 5.73129 16.1501 7.14129 16.1501 8.88129C16.1501 10.6213 14.7401 12.0313 13.0001 12.0313Z"
        fill="currentColor"
      />
    </g>
  
  </svg>
</template>
<script>
export default {};
</script>
<style lang=""></style>

<template>
  <div class="homePageContainer">
    <hero />
    <bottleModel />
    <div class="afterBottleContent">
      <patientDoctorAccess />
      <!-- <tolerability />
      <contact />
      <refrencesTab /> -->
      <footerSec />
    </div>
  </div>
</template>

<script>
// components importing start
import hero from "@/components/homePage/hero.vue";
import bottleModel from "@/components/homePage/bottleModel.vue";
import patientDoctorAccess from "@/components/homePage/patientDoctorAccess.vue";
// import tolerability from "@/components/homePage/tolerability.vue";
// import contact from "@/components/homePage/contact.vue";
// import refrencesTab from "@/components/homePage/refrencesTab.vue";
import footerSec from "@/components/footer.vue";

// components importing end
// importing animation lib for this component start
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import lottie from "lottie-web";
import LocomotiveScroll from "locomotive-scroll";
// importing animation lib for this component end
export default {
  name: "homeComponent",
  components: {
    hero,
    bottleModel,
    patientDoctorAccess,
    // tolerability,
    // contact,
    // refrencesTab,
    footerSec,
  },
  data() {
    return {
      renderComponent: true,
    };
  },
  methods: {
    homeAnim: function () {
      // main scroll function via locomotive start

      gsap.registerPlugin(ScrollTrigger);
      const locoScroll = new LocomotiveScroll({
        el: document.querySelector("#main"),
        smooth: false,
      });
      locoScroll.on("scroll", ScrollTrigger.update);
      // tell ScrollTrigger to use these proxy methods for the ".smooth-scroll" element since Locomotive Scroll is hijacking things
      ScrollTrigger.scrollerProxy("#main", {
        scrollTop(value) {
          return arguments.length
            ? locoScroll.scrollTo(value, 0, 0)
            : locoScroll.scroll.instance.scroll.y;
        }, // we don't have to define a scrollLeft because we're only scrolling vertically.
        getBoundingClientRect() {
          return {
            top: 0,
            left: 0,
            width: window.innerWidth,
            height: window.innerHeight,
          };
        },
        // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
        pinType: document.querySelector("#main").style.transform
          ? "transform"
          : "fixed",
      });
      ScrollTrigger.addEventListener("refresh", () => locoScroll.update());
      ScrollTrigger.refresh();

      // let elementsArray = document.querySelectorAll(".accordianBtn");
      // elementsArray.forEach(function (elem) {
      //   elem.addEventListener("click", function () {
      //     ScrollTrigger.addEventListener("refresh", () => locoScroll.update());
      //     ScrollTrigger.refresh();
      //   });
      // });

      // main scroll function via locomotive end
      // bottle section 3d model animation start
      function bottleAnimationArea() {
        const canvas = document.querySelector("canvas");
        const context = canvas.getContext("2d");

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        window.addEventListener("resize", function () {
          canvas.width = window.innerWidth;
          canvas.height = window.innerHeight;
          render();
        });

        function files(index) {
          var data = `
      ../model/0001.png
      ../model/0002.png
      ../model/0003.png
      ../model/0004.png
      ../model/0005.png
      ../model/0006.png
      ../model/0007.png
      ../model/0008.png
      ../model/0009.png
      ../model/0010.png
      ../model/0011.png
      ../model/0012.png
      ../model/0013.png
      ../model/0014.png
      ../model/0015.png
      ../model/0016.png
      ../model/0017.png
      ../model/0018.png
      ../model/0019.png
      ../model/0020.png
      ../model/0021.png
      ../model/0022.png
      ../model/0023.png
      ../model/0024.png
      ../model/0025.png
      ../model/0026.png
      ../model/0027.png
      ../model/0028.png
      ../model/0029.png
      ../model/0030.png
      ../model/0031.png
      ../model/0032.png
      ../model/0033.png
      ../model/0034.png
      ../model/0035.png
      ../model/0036.png
      ../model/0037.png
      ../model/0038.png
      ../model/0039.png
      ../model/0040.png
      ../model/0041.png
      ../model/0042.png
      ../model/0043.png
      ../model/0044.png
      ../model/0045.png
      ../model/0046.png
      ../model/0047.png
      ../model/0048.png
      ../model/0049.png
      ../model/0050.png
      ../model/0051.png
      ../model/0052.png
      ../model/0053.png
      ../model/0054.png
      ../model/0055.png
      ../model/0056.png
      ../model/0057.png
      ../model/0058.png
      ../model/0059.png
      ../model/0060.png
      ../model/0061.png
      ../model/0062.png
      ../model/0063.png
      ../model/0064.png
      ../model/0065.png
      ../model/0066.png
      ../model/0067.png
      ../model/0068.png
      ../model/0069.png
      ../model/0070.png
      ../model/0071.png
      ../model/0072.png
      ../model/0073.png
      ../model/0074.png
      ../model/0075.png
      ../model/0076.png
      ../model/0077.png
      ../model/0078.png
      ../model/0079.png
      ../model/0080.png
      ../model/0081.png
      ../model/0082.png
      ../model/0083.png
      ../model/0084.png
      ../model/0085.png
      ../model/0086.png
      ../model/0087.png
      ../model/0088.png
      ../model/0089.png
      ../model/0090.png
      ../model/0091.png
      ../model/0092.png
      ../model/0093.png
      ../model/0094.png
      ../model/0095.png
      ../model/0096.png
      ../model/0097.png
      ../model/0098.png
      ../model/0099.png
      ../model/0100.png
      ../model/0101.png
      ../model/0102.png
      ../model/0103.png
      ../model/0104.png
      ../model/0105.png
      ../model/0106.png
      ../model/0107.png
      ../model/0108.png
      ../model/0109.png
      ../model/0110.png
      ../model/0111.png
      ../model/0112.png
      ../model/0113.png
      ../model/0114.png
      ../model/0115.png
      ../model/0116.png
      ../model/0117.png
      ../model/0118.png
      ../model/0119.png
      ../model/0120.png
      ../model/0121.png
      ../model/0122.png
      ../model/0123.png
      ../model/0124.png
      ../model/0125.png
      ../model/0126.png
      ../model/0127.png
      ../model/0128.png
      ../model/0129.png
      ../model/0130.png
      ../model/0131.png
      ../model/0132.png
      ../model/0133.png
      ../model/0134.png
      ../model/0135.png
      ../model/0136.png
      ../model/0137.png
      ../model/0138.png
      ../model/0139.png
      ../model/0140.png
      ../model/0141.png
      ../model/0142.png
      ../model/0143.png
      ../model/0144.png
      ../model/0145.png
      ../model/0146.png
      ../model/0147.png
      ../model/0148.png
      ../model/0149.png
      ../model/0150.png
      ../model/0151.png
      ../model/0152.png
      ../model/0153.png
      ../model/0154.png
      ../model/0155.png
      ../model/0156.png
      ../model/0157.png
      ../model/0158.png
      ../model/0159.png
      ../model/0160.png
      ../model/0161.png
      ../model/0162.png
      ../model/0163.png
      ../model/0164.png
      ../model/0165.png
      ../model/0166.png
      ../model/0167.png
      ../model/0168.png
      ../model/0169.png
      ../model/0170.png
      ../model/0171.png
      ../model/0172.png
      ../model/0173.png
      ../model/0174.png
      ../model/0175.png
      ../model/0176.png
      ../model/0177.png
      ../model/0178.png
      ../model/0179.png
      ../model/0180.png
      ../model/0181.png
      ../model/0182.png
      ../model/0183.png
      ../model/0184.png
      ../model/0185.png
      ../model/0186.png
      ../model/0187.png
      ../model/0188.png
      ../model/0189.png
      ../model/0190.png`;
          return data.split("\n")[index];
        }

        const frameCount = 190;

        const images = [];
        const imageSeq = {
          frame: 1,
        };

        for (let i = 0; i < frameCount; i++) {
          const img = new Image();
          img.src = files(i);
          images.push(img);
        }

        gsap.to(imageSeq, {
          frame: frameCount - 1,
          snap: "frame",
          ease: `none`,
          scrollTrigger: {
            scrub: 0.15,
            trigger: `#bottleMainContainer>canvas`,
            start: `top top`,
            end: `600% top`,
            scroller: `#main`,
          },
          onUpdate: render,
        });

        images[1].onload = render;

        function render() {
          scaleImage(images[imageSeq.frame], context);
        }

        function scaleImage(img, ctx) {
          var canvas = ctx.canvas;
          var hRatio = canvas.width / img.width;
          var vRatio = canvas.height / img.height;
          var ratio = Math.max(hRatio, vRatio);
          var centerShift_x = (canvas.width - img.width * ratio) / 2;
          var centerShift_y = (canvas.height - img.height * ratio) / 2;
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(
            img,
            0,
            0,
            img.width,
            img.height,
            centerShift_x,
            centerShift_y,
            img.width * ratio,
            img.height * ratio
          );
        }
        ScrollTrigger.create({
          trigger: "#bottleMainContainer>canvas",
          pin: true,
          // markers:true,
          scroller: `#main`,
          start: `center center`,
          end: `600% top`,
        });
        ScrollTrigger.create({
          trigger: "#bottleMainContainer>.bottleBgArea",
          pin: true,
          // markers:true,
          scroller: `#main`,
          start: `top top`,
          end: `700% top`,
        });

        ScrollTrigger.create({
          trigger: "#bottleMainContainer>.arrowArea",
          pin: true,
          scroller: `#main`,
          start: `50% top`,
          end: `500% top`,
        });
        ScrollTrigger.create({
          trigger: "#bottleMainContainer>.arrowArea2",
          pin: true,
          scroller: `#main`,
          start: `100% top`,
          end: `500% top`,
        });
        ScrollTrigger.create({
          trigger: "#bottleMainContainer>.arrowArea3",
          pin: true,
          scroller: `#main`,
          start: `150% top`,
          end: `500% top`,
        });
        ScrollTrigger.create({
          trigger: "#bottleMainContainer>.arrowArea4",
          pin: true,
          scroller: `#main`,
          start: `150% top`,
          end: `500% top`,
        });


        ScrollTrigger.create({
          trigger: "#bottleMainContainer>.canvasBg",
          pin: true,
          // markers:true,
          scroller: `#main`,
          start: `top top`,
          end: `700% top`,
        });

        gsap.to("#emptPage1", {
          scrollTrigger: {
            trigger: `#emptPage1`,
            start: `top top`,
            end: `bottom top`,
            pin: true,
            scroller: `#main`,
          },
        });
        gsap.to("#emptPage2", {
          scrollTrigger: {
            trigger: `#emptPage2`,
            start: `top top`,
            end: `bottom top`,
            pin: true,
            scroller: `#main`,
          },
        });
        gsap.to("#emptPage3", {
          scrollTrigger: {
            trigger: `#emptPage3`,
            start: `top top`,
            end: `bottom top`,
            pin: true,
            scroller: `#main`,
          },
        });

        // animated arrow with bodymovin and gsap code start

        // var animatedArrow = "../lottieFiles/simpleArrow.json";

        // let playhead = { frame: 0 },
        //   animation = lottie.loadAnimation({
        //     container: document.querySelector(".arrowAndTxtContainer"),
        //     renderer: "svg",
        //     loop: false,
        //     pin: true,
        //     autoplay: false,
        //     // path: "./arrow.json"
        //     path: animatedArrow,
        //   });

        // animation.addEventListener("DOMLoaded", function () {
        //   let animatedArrowTl = gsap.timeline({
        //     scrollTrigger: {
        //       trigger: ".arrowArea",
        //       start: "50% top", // when the top of the trigger hits the top of the viewport
        //       end: "bottom bottom",
        //       markers: false,
        //       scroller: `#main`,
        //       scrub: 1.5, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
        //     },
        //   });
        //   console.log(animation.totalFrames);
        //   animatedArrowTl.to(playhead, {
        //     frame: 120,
        //     duration: 1,
        //     ease: "none",
        //     onUpdate: () => animation.goToAndStop(playhead.frame, true),
        //   });
        // });

        // let playhead2 = { frame: 0 },
        //   animation2 = lottie.loadAnimation({
        //     container: document.querySelector(".arrowAndTxtContainer2"),
        //     renderer: "svg",
        //     loop: false,
        //     pin: true,
        //     autoplay: false,
        //     // path: "./arrow.json"
        //     path: animatedArrow,
        //   });

        // animation2.addEventListener("DOMLoaded", function () {
        //   let animatedArrowTl = gsap.timeline({
        //     scrollTrigger: {
        //       trigger: ".arrowArea",
        //       start: "50% top", // when the top of the trigger hits the top of the viewport
        //       end: "bottom bottom",
        //       markers: false,
        //       scroller: `#main`,
        //       scrub: 1.5, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
        //     },
        //   });
        //   console.log(animation2.totalFrames);
        //   animatedArrowTl.to(playhead2, {
        //     frame: 120,
        //     duration: 1,
        //     ease: "none",
        //     onUpdate: () => animation2.goToAndStop(playhead2.frame, true),
        //   });
        // });

        // let playhead3 = { frame: 0 },
        //   animation3 = lottie.loadAnimation({
        //     container: document.querySelector(".arrowAndTxtContainer3"),
        //     renderer: "svg",
        //     loop: false,
        //     pin: true,
        //     autoplay: false,
        //     // path: "./arrow.json"
        //     path: animatedArrow,
        //   });

        // animation3.addEventListener("DOMLoaded", function () {
        //   let animatedArrowTl = gsap.timeline({
        //     scrollTrigger: {
        //       trigger: ".arrowArea",
        //       start: "50% top", // when the top of the trigger hits the top of the viewport
        //       end: "bottom bottom",
        //       markers: false,
        //       scroller: `#main`,
        //       scrub: 1.5, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
        //     },
        //   });
        //   console.log(animation3.totalFrames);
        //   animatedArrowTl.to(playhead3, {
        //     frame: 120,
        //     duration: 1,
        //     ease: "none",
        //     onUpdate: () => animation3.goToAndStop(playhead3.frame, true),
        //   });
        // });

        // let playhead4 = { frame: 0 },
        //   animation4 = lottie.loadAnimation({
        //     container: document.querySelector(".arrowAndTxtContainer4"),
        //     renderer: "svg",
        //     loop: false,
        //     pin: true,
        //     autoplay: false,
        //     // path: "./arrow.json"
        //     path: animatedArrow,
        //   });

        // animation4.addEventListener("DOMLoaded", function () {
        //   let animatedArrowTl = gsap.timeline({
        //     scrollTrigger: {
        //       trigger: ".arrowArea",
        //       start: "50% top", // when the top of the trigger hits the top of the viewport
        //       end: "bottom bottom",
        //       markers: false,
        //       scroller: `#main`,
        //       scrub: 10, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
        //     },
        //   });
        //   console.log(animation4.totalFrames);
        //   animatedArrowTl.to(playhead4, {
        //     frame: 120,
        //     duration: 10,
        //     ease: "none",
        //     onUpdate: () => animation4.goToAndStop(playhead4.frame, true),
        //   });
        // });

        // animated arrow with bodymovin and gsap code end

        // bottle labels appearing code start
        const bottleLabel = gsap.timeline({
          scrollTrigger: {
            trigger: ".bottleLabelContainer",
            start: "+=350 80%",
            end: "+=500 40%",
            scrub: true,
            markers: false,
            scroller: `#main`,
          },
        });
        bottleLabel.to(".bottleLabelContainer", { opacity: 1, duration: 0.5 });
        const bottleLabel2 = gsap.timeline({
          scrollTrigger: {
            trigger: ".bottleLabelContainer2",
            start: "+=350 100%",
            end: "+=500 60%",
            scrub: true,
            markers: false,
            scroller: `#main`,
          },
        });
        bottleLabel2.to(".bottleLabelContainer2", { opacity: 1, duration: 0.5 });

        // bottle labels appearing code end
      }
      bottleAnimationArea();
      // bottle section 3d model animation end
    },
  },
  mounted: function mounted() {
    this.homeAnim();

    // hubspot contact form genarting  code start
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "8053583",
          region: "na1",
          formId: "90443460-23e8-4380-9ee5-0ff37de76c12",
          target: "#hubspotContactForm",
        });
      }
    });
    // hubspot contact form genarting  code end
  },
};
</script>

<template lang="">
  <div class="footerOuterContainer">
    <footer class="footerContainer">
      <img class="footerBgImg" src="@/assets/img/footerBg.svg" alt="" />

      <div class="mainContainer">
        <div class="warningAndPrecuationSec">
          <div class="warningMainHeading">
            <h2>Mises en garde et <span>précautions</span></h2>
          </div>
          <div class="warningTxt" v-bind:class="{ active: warningContent }">
            <p>
              <strong> Indication </strong>
              ZIMED<sup>MD</sup> PF (solution ophtalmique de bimatoprost à 0,03
              % p/v) est indiqué pour réduire la pression intraoculaire élevée
              chez les patients présentant un glaucome à angle ouvert ou une
              hypertension oculaire
            </p>
            <p>
              <strong> Contre-indication </strong>
              ZIMED<sup>MD</sup> PF est contre-indiqué chez les patients qui présentent une
              hypersensibilité au produit ou à un ingrédient de la formulation,
              y compris un ingrédient non médicinal ou un composant du contenant
            </p>
            <p>
              <strong>Mises en garde plus graves et précautions </strong>
              • <b>Inflammation oculaire :</b> utilisez le produit avec prudence <br />
              • <b>Œdème maculaire :</b> utilisez le produit avec prudence chez les patients présentant des facteurs de risque d’œdème maculaire
            </p>
            <p>
              <strong> Autres mises en garde pertinentes et précautions </strong>

              • <b>Pigmentation accrue :  </b> de l’iris, des tissus périobitaires ou des cils <br />
              • <b>Argent :</b> évitez d’utiliser le produit en cas d’antécédents de sensibilité à l’argent
              <br />
              • <b>Femmes enceintes : </b> n’administrez le produit durant la grossesse
            </p>
            <p>
              <strong> Pour plus de renseignements : </strong>
              Consultez la
              <a href="/files/00068797.PDF" download class="underLineLink"
                >monographie de produit</a
              >
              pour les renseignements importants concernant les effets indésirables, les interactions médicamenteuses et les renseignements posologiques qui n’ont pas été abordés ici. On peut aussi obtenir la monographie de produit en composant le
              
              <a href="tel:1-833-542-2633">1-833-542-2633.</a>
            </p>
          </div>
          <div
            class="lottieBtn accordianBtn"
            v-on:click="toggleTab()"
            v-bind:class="{ rotateBtn: warningContent }"
          >
          <img src="@/assets/img/lottieBtn.gif" alt="" />
          </div>
        </div>
        <div class="footerContent">
          <a href="/" class="ftLogo">
            <img src="@/assets/img/ftLogo1.svg" alt="" />
          </a>
          <div class="ftCompanyMenu">
            <h3>Entreprise</h3>
            <ul>
              <li>
                <router-link to="/patients-french">
                  Accès des patients
                </router-link>
              </li>
              <li>
                <router-link to="/doctors-french">
                  Accès des médecins
                </router-link>
              </li>
              <li>
                <a href="./files/Zimed-Privacy-Policy-FR.PDF" download>Politique de confidentialité</a>
              </li>
            </ul>
          </div>
          <div class="ftCompanyMenu ftCompany2">
            <h3>Contactez-nous</h3>
            <ul>
              <li>
                <a href="mailto:contact@aequuspharma.ca">
                  <span class="emailIcon"><emailIcon /></span>
                  contact@aequuspharma.ca
                </a>
              </li>
              <li>
                <a href="tel:18335422633">
                  <span class="phoneIcon"><callIcon /></span> 1-833-542-2633
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <span class="locationIcon"><locationIcon /></span> 2820 - 200
                  Granville St.<br />
                  Vancouver, BC <br />V6C 1S4</a
                >
              </li>
            </ul>
          </div>
          <a href="/fr" class="ftLogo2">
            <img src="@/assets/img/ftLogo2French.svg" alt="" />
          </a>
        </div>
        <div class="ftBtmRow">
          <p class="copyTxt">Copyrights Tous droits réservés @Aequus Pharmaceuticals Inc.</p>
          <ul class="policyLinks">
            <!-- <li>
                <a href="#">Privacy Policy</a>
              </li>
              <li>
                <a href="#lotieBtn">Terms of service</a>
              </li> -->
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import animationJSON from "@/assets/img/scrollDownBtn.json";
import callIcon from "@/components/homePage/snippets/callIcon.vue";
import locationIcon from "@/components/homePage/snippets/locationIcon.vue";
import emailIcon from "@/components/homePage/snippets/emailIcon.vue";
export default {
  name: "footer",
  data() {
    return {
      warningContent: false,
      animationJSON,
    };
  },
  components: {
    callIcon,
    locationIcon,
    emailIcon,
  },
  methods: {
    toggleTab() {
      this.warningContent = !this.warningContent;
    },
  },
};
</script>
<style lang=""></style>

<template lang="">
  <div class="reportInfoWithButton">
    <!-- <div class="sideFadeBgCircle"></div> -->
    <div class="mainContainer">
      <div class="btnsContainer">
        <router-link to="/patients" class="extraBtn">
          Patient Access
           <span class="btnIcon">
            <svg fill="none" viewBox="0 0 24 25"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M22 12.5a10 10 0 11-10-10M22 2.5l-10 10M16 2.5h6v6"/></svg>
           </span>
        </router-link>
        <router-link to="/doctors" class="extraBtn">HCP Access<span class="btnIcon">
            <svg fill="none" viewBox="0 0 24 25"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M22 12.5a10 10 0 11-10-10M22 2.5l-10 10M16 2.5h6v6"/></svg>
           </span></router-link>
      </div>
      
    </div>
  </div>
</template>
<script>
export default {
  name: "patientDoctorAccess",
};
</script>
<style lang=""></style>

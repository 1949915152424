import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

import PageNotFound from "../views/PageNotFound.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: 'Home Page',
      description: 'Welcome to our website.'
    },
  },
  {
    path: "/patients",
    name: "patients",
    component: () => import("../views/PatientView.vue"),
  },
  {
    path: "/doctors",
    name: "doctors",
    component: () => import("../views/DoctorView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/ContactView.vue"),
  },
  {
    path: "/faq-patients",
    name: "faq-patients",
    component: () => import("../views/FAQ-Patients.vue"),
  },
  {
    path: "/faq-hcps",
    name: "faq-hcps",
    component: () => import("../views/FAQ-HCP.vue"),
  },
  {
    path: "/fr",
    name: "fr",
    component: () => import("../views/frenchHomeView.vue"),
  },
  {
    path: "/patients-french",
    name: "patients-french",
    component: () => import("../views/PatientViewFrench.vue"),
  },
  {
    path: "/doctors-french",
    name: "doctors-french",
    component: () => import("../views/DoctorViewFrench.vue"),
  },
  {
    path: "/contact-french",
    name: "contact-french",
    component: () => import("../views/ContactViewFrecnh.vue"),
  },
  {
    path: "/faq-patients-french",
    name: "faq-patients-french",
    component: () => import("../views/FAQ-Patients-French.vue"),
  },
  {
    path: "/faq-hcps-french",
    name: "faq-hcps-french",
    component: () => import("../views/FAQ-HCP-French.vue"),
  },
  {
    path: "/patients-resources",
    name: "patients-resources",
    component: () => import("../views/blogsView.vue"),
  },
  {
    path: "/hcp-resources",
    name: "hcp-resources",
    component: () => import("../views/hcpResourcesView.vue"),
  },
  {
    path: "/patients-resources-french",
    name: "patients-resources-french",
    component: () => import("../views/blogsFrenchView.vue"),
  },
  {
    path: "/hcp-resources-french",
    name: "hcp-resources-french",
    component: () => import("../views/hcpResourcesFrenchView.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/admin.vue"),
    beforeEnter: (to, from, next) => {
      // Check if the user is already logged in (adminLoggedIn is true)
      if (localStorage.getItem("adminLoggedIn") === "true") {
        // Redirect to the dashboard route
        next({ name: "dashboard" });
      } else {
        // Allow access to the admin login form
        next();
      }
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: PageNotFound,
  },
  {
    path: "/patients-resources/article/:id",
    name: "ArticleDetail",
    component: () => import("../components/blogPage/ArticleDetail.vue"),
  },
  {
    path: "/patients-resources-french/article/:id",
    name: "ArticleDetailFrench",
    component: () => import("../components/blogPageFrench/ArticleDetailFrench.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/dashboard.vue"),
    beforeEnter: (to, from, next) => {
      // Check if the user is already logged in (adminLoggedIn is true)
      if (localStorage.getItem("adminLoggedIn") != "true") {
        // Redirect to the dashboard route
        next({ name: "not-found" });
      } else {
        // Allow access to the admin login form
        next();
      }
    },

  },
  {
    path: "/product-monograph",
    name: "product-monograph",
    component: () => import("../views/product-monograph.vue"),
  },
  {
    path: "/product-monograph-french",
    name: "product-monograph-french",
    component: () => import("../views/product-monograph-french.vue"),
  }

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

export default router;

<template lang="">
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.05 15.5213L9.2 17.3713C8.81 17.7613 8.19 17.7613 7.79 17.3813C7.68 17.2713 7.57 17.1713 7.46 17.0613C6.43 16.0213 5.5 14.9313 4.67 13.7913C3.85 12.6513 3.19 11.5113 2.71 10.3813C2.24 9.24129 2 8.15129 2 7.11129C2 6.43129 2.12 5.78129 2.36 5.18129C2.6 4.57129 2.98 4.01129 3.51 3.51129C4.15 2.88129 4.85 2.57129 5.59 2.57129C5.87 2.57129 6.15 2.63129 6.4 2.75129C6.66 2.87129 6.89 3.05129 7.07 3.31129L9.39 6.58129C9.57 6.83129 9.7 7.06129 9.79 7.28129C9.88 7.49129 9.93 7.70129 9.93 7.89129C9.93 8.13129 9.86 8.37129 9.72 8.60129C9.59 8.83129 9.4 9.07129 9.16 9.31129L8.4 10.1013C8.29 10.2113 8.24 10.3413 8.24 10.5013C8.24 10.5813 8.25 10.6513 8.27 10.7313C8.3 10.8113 8.33 10.8713 8.35 10.9313C8.53 11.2613 8.84 11.6913 9.28 12.2113C9.73 12.7313 10.21 13.2613 10.73 13.7913C10.83 13.8913 10.94 13.9913 11.04 14.0913C11.44 14.4813 11.45 15.1213 11.05 15.5213Z"
      fill="currentColor"
    />
    <path
      d="M21.9701 18.9013C21.9701 19.1813 21.9201 19.4713 21.8201 19.7513C21.7901 19.8313 21.7601 19.9113 21.7201 19.9913C21.5501 20.3513 21.3301 20.6913 21.0401 21.0113C20.5501 21.5513 20.0101 21.9413 19.4001 22.1913C19.3901 22.1913 19.3801 22.2013 19.3701 22.2013C18.7801 22.4413 18.1401 22.5713 17.4501 22.5713C16.4301 22.5713 15.3401 22.3313 14.1901 21.8413C13.0401 21.3513 11.8901 20.6913 10.7501 19.8613C10.3601 19.5713 9.9701 19.2813 9.6001 18.9713L12.8701 15.7013C13.1501 15.9113 13.4001 16.0713 13.6101 16.1813C13.6601 16.2013 13.7201 16.2313 13.7901 16.2613C13.8701 16.2913 13.9501 16.3013 14.0401 16.3013C14.2101 16.3013 14.3401 16.2413 14.4501 16.1313L15.2101 15.3813C15.4601 15.1313 15.7001 14.9413 15.9301 14.8213C16.1601 14.6813 16.3901 14.6113 16.6401 14.6113C16.8301 14.6113 17.0301 14.6513 17.2501 14.7413C17.4701 14.8313 17.7001 14.9613 17.9501 15.1313L21.2601 17.4813C21.5201 17.6613 21.7001 17.8713 21.8101 18.1213C21.9101 18.3713 21.9701 18.6213 21.9701 18.9013Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {};
</script>
<style lang=""></style>

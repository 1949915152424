import { createApp } from 'vue'
import App from './App.vue'
import VueGtag from 'vue-gtag'

import firebase from 'firebase/app';
import 'firebase/firestore'; // Import Firestore module
import 'firebase/auth'; // Import Firebase authentication module

const firebaseConfig = {
    apiKey: "AIzaSyBRBYPr7s1hy7t4mxTSt9kPaVz8srgnbUc",
    authDomain: "zimed-dashboard.firebaseapp.com",
    projectId: "zimed-dashboard",
    storageBucket: "zimed-dashboard.appspot.com",
    messagingSenderId: "124685145068",
    appId: "1:124685145068:web:e57832fe5446617cab26c9",
    measurementId: "G-SHSCZ7NY63"
  };

firebase.initializeApp(firebaseConfig);



import './registerServiceWorker';
import router from './router';
// lottie player import 
// import Vue3Lottie from 'vue3-lottie'


// locomotive scroll css file 
import "./assets/vendors/locomotive-scroll.css";
// custom css file for this project 
import "./assets/css/styles.css";

import { createMetaManager, plugin } from 'vue-meta';


const app = createApp(App);

app.use(router);
app.use(createMetaManager());

app.use(VueGtag, { config: { id: 'G-388270590' } });

app.mount('#app');

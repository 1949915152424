<template lang="">
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
    <template v-slot:description="{ metainfo }">{{
      metainfo.description
    }}</template>
  </metainfo>
  <div class="page404">
    <div class="error404">
      <h1>404</h1>
      <p>We're sorry but it looks like that page doesn't exist anymore.</p>
      <a href="/" class="simpleBtn">Back</a>
    </div>
  </div>
</template>
<script>
import { useMeta } from "vue-meta";
export default {
  name: "404",
  setup() {
    useMeta({
      title: "404 Not Found",
      description: "404 Not Found",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    });
  },
};
</script>
<style lang="css" scope>
.page404 {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: #f8f8f8;
  color: #303532;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error404 {
  text-align: center;
  padding: 16px;
  position: relative;
}

.error404 h1 {
  margin: -10px 0 -30px;
  font-size: calc(17vw + 40px);
  opacity: 0.8;
  letter-spacing: -17px;
}

.error404 p {
  opacity: 0.8;
  font-size: 20px;
  margin: 8px 0 38px 0;
  font-weight: bold;
}

.error404 button {
  border: 0;
  outline: 0 !important;
}
.error404 button button {
  width: 40px;
  padding: 14.5px 16px 14.5px 12.5px;
  vertical-align: top;
  border-radius: 0 6px 6px 0;
  color: grey;
  background: silver;
  cursor: pointer;
  transition: all 0.4s;
}
</style>

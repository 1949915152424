<template lang="">
  <div id="bottleMainContainer">
    <div class="canvasBg"></div>
    <canvas></canvas>
    <div class="bottleBgArea">
      <img
        src="@/assets/img/shadow.png"
        class="bottleShadow"
        width=""
        height=""
        alt=""
      />
    </div>
    <div class="arrowArea">
      <div class="arrowAreaInnerContent">
        <div
          class="arrowAndTxtContainer bottleLabelContainer leftTopTxtContainer"
        >
          <p class="bottleTxt">
            Multi-dose <br />
            Container
          </p>
        </div>
      </div>
    </div>
    <div class="arrowArea2">
      <div class="arrowAreaInnerContent">
        <div
          class="arrowAndTxtContainer2 bottleLabelContainer2 rightTopTxtContainer"
        >
          <p class="bottleTxt">
            No Refrigeration <br />
            Needed (15°C - 25°C)
          </p>
        </div>
      </div>
    </div>
    <div class="arrowArea3">
      <div class="arrowAreaInnerContent">
        <div
          class="arrowAndTxtContainer3 bottleLabelContainer leftBottomTxtContainer"
        >
          <p class="bottleTxt">
            28 Microlitre <br />
            Drop
          </p>
        </div>
      </div>
    </div>
    <div class="arrowArea4">
      <div class="arrowAreaInnerContent">
        <div
          class="arrowAndTxtContainer3 bottleLabelContainer rightBottomTextContainer"
        >
          <p class="bottleTxt">1 Drop a Day<br />Per Affected Eye</p>
        </div>
      </div>
    </div>
  </div>
  <div class="emptyPagesContainer">
    <div id="emptPage1"></div>
    <div id="emptPage2"></div>
    <div id="emptPage3"></div>
  </div>
</template>
<script>
export default {
  name: "bottleModel",
  watch: {
    $route() {
      // this will be called any time the route changes
    },
  },
};
</script>
<style lang=""></style>

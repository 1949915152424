<template lang="">
  <div class="mainContainer">
    <div class="mainNavigationContainer" v-bind:class="{ active: showMenu }">
      <nav class="navBar">
        <div class="mainLogoContainer">
          <a href="/">
            <img src="@/assets/img/logo.svg" width="" height="" alt="" />
          </a>
        </div>
        <ul class="sideMenuItems">
          <li class="hideLi2" v-if="typeNav === 'doctors' || typeNav === 'hcp-resources' || typeNav === 'faq-hcps'  || typeNav === 'product-monograph'  || typeNav === 'contact'">
            <!-- <a href="/files/00068797.PDF" download class="navLink">
              Product Monograph
            </a> -->
          <router-link
              v-on:click="closeMenu()"
              to="/product-monograph"
              class="navLink"
            >
            Product Monograph
            </router-link> 
          </li>
          <li>
            <router-link
              v-on:click="closeMenu()"
              to="/patients"
              class="navLink"
            >
              Patient Access
            </router-link>
          </li>
          <li>
            <router-link v-on:click="closeMenu()" to="/doctors" class="navLink">
              HCP Access
            </router-link>
          </li>

          <li
            class="hideLi"
            v-if="typeNav === 'patients' || typeNav === 'patients-resources'  || typeNav === 'faq-patients'"
          >
            <router-link
              v-on:click="closeMenu()"
              to="/patients-resources"
              class="navLink"
              >Patient's Resources
            </router-link>
          </li>

          <li
            class="hideLi2"
            v-if="typeNav === 'doctors' || typeNav === 'hcp-resources' || typeNav === 'faq-hcps'  || typeNav === 'product-monograph' || typeNav === 'contact'"
          >
            <router-link
              v-on:click="closeMenu()"
              to="/hcp-resources"
              class="navLink"
              >HCP Resources
            </router-link>
          </li>

          <li class="hideLi2" v-if="typeNav === 'doctors'  || typeNav === 'hcp-resources' || typeNav === 'faq-hcps'  || typeNav === 'product-monograph' || typeNav === 'contact'">
            <router-link
              v-on:click="closeMenu()"
              to="/faq-hcps"
              class="navLink"
            >
              FAQ
            </router-link>
          </li>
          <li class="hideLi" v-if="typeNav === 'patients'  || typeNav === 'patients-resources'  || typeNav === 'faq-patients'">
            <router-link
              v-on:click="closeMenu()"
              to="/faq-patients"
              class="navLink"
            >
              FAQ
            </router-link>
          </li>
          <li class="hideLi2" v-if="typeNav === 'doctors'  || typeNav === 'hcp-resources' || typeNav === 'faq-hcps'  || typeNav === 'product-monograph' || typeNav === 'contact'">
            <router-link
              v-on:click="closeMenu()"
              to="/contact"
              class="navBtnStyleLink"
              >Request Samples</router-link
            >
          </li>

          <li class="enFrBtn" v-bind:class="{ showDropDown: showDropDown }">
            <button
              id="engBtn"
              class="navBtnStyleLinkActive"
              v-on:click="langSelector()"
            >
              En
              <span>
                <svg
                  width="14"
                  height="7"
                  viewBox="0 0 14 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 0.5L7 6.5L1 0.5"
                    stroke="#303532"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </button>
            <span class="langDropDown">
             <router-link
              v-on:click="closeMenu()"
              to="/fr"
              class="navBtnStyleLink"
               >
               Fr
              </router-link> 
            </span>
          </li>
          <li class="zoomInOutBtns">
            <button class="zoomOutBtn disabledBtn" @click="zoomOut">
              <span v-html="zoomOutIcon"></span>
            </button>
            <button class="zoomInBtn" @click="zoomIn">
              <span v-html="zoomInIcon"></span>
            </button>
          </li>
        </ul>
        <div class="hamburger" v-on:click="toggleMenu()">
          <span class="line"></span>
          <span class="line"></span>
          <span class="line"></span>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  name: "navigation",
  data() {
    return {
      showMenu: false,
      showDropDown: false,
      licenseIdNumber: false, // To track whether the entered identification number is correct or not.
      zoomInIcon: `<svg fill="currentColor" viewBox="0 0 1920 1920"><path fill-rule="evenodd" d="M1920 1766.678l-368.126-368.234c114.287-146.817 183.033-330.826 183.033-530.99C1734.907 389.16 1345.746 0 867.454 0 389.16 0 0 389.161 0 867.454c0 478.292 389.161 867.453 867.454 867.453 200.164 0 384.065-68.854 530.99-183.033L1766.678 1920 1920 1766.678zM867.454 1518.044c-358.8 0-650.59-291.79-650.59-650.59s291.79-650.59 650.59-650.59 650.59 291.79 650.59 650.59-291.79 650.59-650.59 650.59zM975.885 487.943H759.022v271.079h-271.08v216.863h271.08v271.08h216.863v-271.08h271.08V759.022h-271.08v-271.08z"/></svg>`,
      zoomOutIcon: `<svg fill="currentColor" viewBox="0 0 1920 1920"><path fill-rule="evenodd" d="M867.454 1518.044c-358.8 0-650.59-291.79-650.59-650.59s291.79-650.59 650.59-650.59 650.59 291.79 650.59 650.59-291.79 650.59-650.59 650.59zM1920 1766.678l-368.126-368.234c114.287-146.817 183.033-330.826 183.033-530.99C1734.907 389.16 1345.746 0 867.454 0 389.16 0 0 389.161 0 867.454c0 478.292 389.161 867.453 867.454 867.453 200.164 0 384.065-68.854 530.99-183.033L1766.678 1920 1920 1766.678zM487.943 975.885h759.021V759.022H487.943v216.863z"/></svg>`,
      currentZoomLevelIndex: 0,
    };
  },
  props: {
    typeNav: String,
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      this.showMenu = false;
    },
    langSelector() {
      this.showDropDown = !this.showDropDown;
    },
    zoomIn() {
      if (this.currentZoomLevelIndex < 3) {
        this.currentZoomLevelIndex++;
        this.adjustTextFontSizes(3);
        if (this.currentZoomLevelIndex === 3) {
          // Reached max zoom in, disable zoom in button
          document.querySelector(".zoomInBtn").classList.add("disabledBtn");
        }
        // Remove disabled class from zoom out button if present
        document.querySelector(".zoomOutBtn").classList.remove("disabledBtn");
      }
    },
    zoomOut() {
      if (this.currentZoomLevelIndex > 0) {
        this.currentZoomLevelIndex--;
        this.adjustTextFontSizes(-3);
        if (this.currentZoomLevelIndex === 0) {
          // Reached min zoom out, disable zoom out button
          document.querySelector(".zoomOutBtn").classList.add("disabledBtn");
        }
        // Remove disabled class from zoom in button if present
        document.querySelector(".zoomInBtn").classList.remove("disabledBtn");
      }
    },
    adjustTextFontSizes(delta) {
      const textNodes = this.getAllTextNodes(document.body);
      textNodes.forEach((node) => {
        let fontSize = parseInt(
          window.getComputedStyle(node.parentElement).fontSize
        );
        fontSize += delta;
        node.parentElement.style.fontSize = fontSize + "px";
      });
    },
    getAllTextNodes(element) {
      const walker = document.createTreeWalker(
        element,
        NodeFilter.SHOW_TEXT,
        null,
        false
      );
      const textNodes = [];
      while (walker.nextNode()) {
        const node = walker.currentNode;
        if (node.nodeType === Node.TEXT_NODE && node.nodeValue.trim() !== "") {
          textNodes.push(node);
        }
      }
      return textNodes;
    },
  },
};
</script>
<style lang=""></style>

<template lang="">
  <div class="footerOuterContainer">
    <footer class="footerContainer">
      <img class="footerBgImg" src="@/assets/img/footerBg.svg" alt="" />

      <div class="mainContainer">
        <div class="warningAndPrecuationSec">
          <div class="warningMainHeading">
            <h2>WARNINGS AND <span>PRECAUTIONS</span></h2>
          </div>
          <div class="warningTxt" v-bind:class="{ active: warningContent }">
            <p>
              <strong> Indication </strong>
              ZIMED® PF (bimatoprost ophthalmic solution 0.03% w/v) is indicated
              for: the reduction of elevated intraocular pressure in patients
              with open angle glaucoma or ocular hypertension.
            </p>
            <p>
              <strong> Contraindication </strong>
              ZIMED® PF is contraindicated in patients who are hypersensitive to
              this drug or to any ingredient in the formulation, including any
              non-medicinal ingredient, or component of the container.
            </p>
            <p>
              <strong>Most Serious Warnings and precautions </strong>
              • <b>Ocular inflammation:</b> use with caution. <br />
              • <b>Macular edema:</b> use with caution in patients with macular
              edema risk factors.
            </p>
            <p>
              <strong> Other relevant warnings and precautions </strong>

              • <b>Increased Pigmentation: </b> of iris, periorbital tissue or
              eyelashes. <br />
              • <b>Silver:</b> avoid if history of contact sensitivity to
              silver.
              <br />
              • <b>Pregnant women:</b> should be administered during pregnancy
              only if the potential benefit justifies the potential risk to the
              fetus.
            </p>
            <p>
              <strong> For more information: </strong>
              Consult the <a href="/files/00068797.PDF" download class="underLineLink">Product Monograph</a> for important information relating
              to adverse reactions, drug interactions, and dosing information
              which have not been discussed in this piece.
              <br />
              The Product Monograph is also available by calling 
              <a href="tel:1-833-542-2633">1-833-542-2633.</a>
            </p>
          </div>
          <div
            class="lottieBtn accordianBtn"
            v-on:click="toggleTab()"
            v-bind:class="{ rotateBtn: warningContent }"
          >
           <img src="@/assets/img/lottieBtn.gif" alt="" />
          </div>
        </div>
        <div class="footerContent">
          <a href="/" class="ftLogo">
            <img src="@/assets/img/ftLogo1.svg" alt="" />
          </a>
          <div class="ftCompanyMenu">
            <h3>Company</h3>
            <ul>
              <li>
                <router-link to="/patients"> For Patients </router-link>
              </li>
              <li>
                <router-link to="/doctors"> For HCP </router-link>
              </li>
              <li>
                <a href="https://www.aequuspharma.ca/" target="_blank"> About Aequus Pharmaceutical Inc.</a>
               
              </li>
              <li>
                <a href="./files/Zimed-Privacy-Policy-EN.PDF" download>Privacy Policy</a>
              </li>
            </ul>
          </div>
          <div class="ftCompanyMenu ftCompany2">
            <h3>Reach Us</h3>
            <ul>
              <li>
                <a href="mailto:contact@aequuspharma.ca">
                  <span class="emailIcon"><emailIcon /></span>
                  contact@aequuspharma.ca
                </a>
              </li>
              <li>
                <a href="tel:18335422633">
                  <span class="phoneIcon"><callIcon /></span> 1-833-542-2633
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <span class="locationIcon"><locationIcon /></span> 2820 - 200
                  Granville St.<br />
                  Vancouver, BC <br />V6C 1S4</a
                >
              </li>
            </ul>
          </div>
          <a href="/" class="ftLogo2">
            <img src="@/assets/img/ftLogo2.svg" alt="" />            
          </a>
        </div>
        <div class="ftBtmRow">
          <p class="copyTxt"> Copyrights @2023 Aequus Pharmaceuticals In. All rights reserved. Last updated August 2023</p>
             <ul class="policyLinks">
            <!-- <li>
              <a href="#">Privacy Policy</a>
            </li>
            <li>
              <a href="#lotieBtn">Terms of service</a>
            </li> -->
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import animationJSON from "@/assets/img/scrollDownBtn.json";
import callIcon from "@/components/homePage/snippets/callIcon.vue";
import locationIcon from "@/components/homePage/snippets/locationIcon.vue";
import emailIcon from "@/components/homePage/snippets/emailIcon.vue";
export default {
  name: "footer",
  data() {
    return {
      warningContent: false,
      animationJSON,
    };
  },
  components: {
    callIcon,
    locationIcon,
    emailIcon,
  },
  methods: {
    toggleTab() {
      this.warningContent = !this.warningContent;
    },
  },
};
</script>
<style lang=""></style>

<template lang="">
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M19 4.07129H9C6 4.07129 4 5.57129 4 9.07129V16.0713C4 19.5713 6 21.0713 9 21.0713H19C22 21.0713 24 19.5713 24 16.0713V9.07129C24 5.57129 22 4.07129 19 4.07129ZM19.47 10.1613L16.34 12.6613C15.68 13.1913 14.84 13.4513 14 13.4513C13.16 13.4513 12.31 13.1913 11.66 12.6613L8.53 10.1613C8.21 9.90129 8.16 9.42129 8.41 9.10129C8.67 8.78129 9.14 8.72129 9.46 8.98129L12.59 11.4813C13.35 12.0913 14.64 12.0913 15.4 11.4813L18.53 8.98129C18.85 8.72129 19.33 8.77129 19.58 9.10129C19.84 9.42129 19.79 9.90129 19.47 10.1613Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
<script>
export default {};
</script>
<style lang=""></style>

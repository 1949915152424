<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
    <template v-slot:description="{ metainfo }">{{
      metainfo.description
    }}</template>
  </metainfo>
  <homeComponents />
</template>

<script>
import { useMeta } from "vue-meta";
import homeComponents from "@/components/homeComponents.vue";
export default {
  name: "HomeView",
  components: {
    homeComponents,
  },
  setup() {
    useMeta({
      title: "ZIMED® PF (Preservative-free and Multidose)",
      description: "Zimed® PF is the first preservative free and multi dose Bimatoprost 0.03% in Canada by Aequus Pharmaceutical.",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    });
  },
};
</script>

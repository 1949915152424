<template>
  <div id="main">
    <navigation :typeNav="getTypeNavFromRoute()" v-if="isNotEngIndex && isNotAdmin && isNotFound && isNotEng && !isArticlePageFrench" />
    <navigationFrench :typeNav="getTypeNavFromRoute()" v-if="isNotFrenchIndex && isNotAdmin && isNotFound && isNotFrench && !isArticlePage && !isHcpPage"/>
    <router-view />
    <footerSec v-if="isNotIndex && isNotAdmin && isNotFound && isNotEng && !isArticlePageFrench"  />
    <footerFrench v-if="isNotIndex && isNotAdmin && isNotFound && isNotFrench && !isArticlePage && !isHcpPage" />
  </div>
</template>

<script>
import navigation from "@/components/navigation.vue";
import navigationFrench from "@/components/navigationFrench.vue";
import footerSec from "@/components/footer.vue";
import footerFrench from "@/components/footerFrench.vue";

export default {
  name: "App",
  components: {
    navigation,
    navigationFrench,
    footerSec,
    footerFrench,
  },
  computed: {
    isNotIndex() {
      return this.$route.path !== "/" && this.$route.path !== "/fr";
    },
    isNotFrenchIndex() {
      return this.$route.path !== "/";
    },
    isNotEngIndex() {
      return this.$route.path !== "/fr";
    },
    isNotEng() {
      return this.$route.path !== "/patients-french" && this.$route.path !== "/doctors-french" && this.$route.path !== "/faq-hcps-french" && this.$route.path !== "/faq-patients-french"  && this.$route.path !== "/contact-french" && this.$route.path !== "/hcp-resources-french" && this.$route.path !== "/patients-resources-french" && this.$route.path !== "/product-monograph-french";
    },
    isNotFrench() {
      return this.$route.path !== "/patients" && this.$route.path !== "/doctors" && this.$route.path !== "/faq-hcps" && this.$route.path !== "/faq-patients" && this.$route.path !== "/contact"  && this.$route.path !== "/patients-resources" && this.$route.path !== "/hcp-resources"  && this.$route.path !== "/article"  && this.$route.path !== "/product-monograph";
    },
    isNotAdmin() {
      return this.$route.path !== "/admin" && this.$route.path !== "/dashboard" && this.$route.path !== "/404";
    },
    isNotFound() {
      return this.$route.name !== "not-found"; // Check if the route name is "not-found"
    },
    isArticlePage() {
      return this.$route.path.startsWith("/patients-resources/");
    },
    isArticlePageFrench() {
      return this.$route.path.startsWith("/patients-resources-french/");
    },
    isHcpPage(){
      return this.$route.path.startsWith("/hcp-resources/");      
    }
  },
  methods: {
    getTypeNavFromRoute() {
      const routePath = this.$route.path;
      let typeNav = "";
      if (routePath.startsWith("/")) {
        typeNav = routePath.substring(1); // Remove the leading slash.
      }
      return typeNav;
    },
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
  },
};
</script>

<style lang="scss">
/* Add your styles here */
</style>

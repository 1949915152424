<template lang="">
  <div class="heroSection">
    <div class="heroSectionImg">
      <heroTxt/>
    </div>
    <div class="heroWaveArea">
      <waves />
    </div>
  </div>
</template>
<script>
import waves from "@/components/homePage/snippets/waves.vue";
import heroTxt from "@/components/homePage/snippets/heroTxt.vue"
export default {
  name: "hero",
  components: {
    waves,
    heroTxt,
  },
  data() {
    return {};
  },
};
</script>
<style lang=""></style>
